
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, DELIVER_LOG } from '@/store/actions'
import { IDeliverLog, IStory } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import TransmissionSelector from '@/components/story/common/selectors/TransmissionSelector.vue'
import { constant } from '@/utils/constants'

@Options({
  components: { CLoading, TestModeSelector, TransmissionSelector },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TransmissionLogModal extends Vue {
  @Prop()
  selectedStory!: IStory

  @Prop()
  modalVisible!: boolean

  @Prop()
  campaignId!: string

  loading = false
  isTestMode = false
  queryParams = {}
  rows = []
  firstLoad = false

  pagination = {
    sortBy: 'updated_at',
    descending: true,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 10,
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get resources() {
    const results: {
      _id?: string
      type?: string
      label?: string
    }[] = []
    for (const item of this.messages) {
      results.push({
        _id: item._id,
        type: 'message',
        label: item.title,
      })
    }
    for (const item of this.questions) {
      results.push({
        _id: item._id,
        type: 'question',
        label: item.title,
      })
    }
    for (const item of this.goals) {
      results.push({
        _id: item._id,
        type: 'goal',
        label: item.title,
      })
    }

    for (const item of this.forms) {
      results.push({
        _id: item._id,
        type: 'form',
        label: item.title,
      })
    }
    return results
  }

  async loadResources() {
    // TODO: this should load base on form.story_id
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
    })
  }

  checkDiffirent(to: string, from: string) {
    return to !== from ? 1 : 0
  }

  getResourceTypeName(value) {
    if (value === 'message') {
      return this.$t('menu.message')
    } else if (value === 'question') {
      return this.$t('menu.q_a')
    } else if (value === 'goal') {
      return this.$t('menu.goal')
    } else if (value === 'form') {
      return this.$t('menu.form')
    }

    return ''
  }

  async updateQueryParams(params) {
    this.queryParams = params

    this.$nextTick(() => {
      // get initial data from server (1st page)
      this.$refs.deliverLogsRef.requestServerInteraction()
    })
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loading = true
    const returnedData = await this.fetchFromServer(page, rowsPerPage, filter, sortBy, descending)
    if (returnedData) {
      // eslint-disable-next-line
      const items: [] = returnedData['items']
      this.rows.splice(0, this.rows.length, ...items)
      // eslint-disable-next-line
      this.pagination.rowsNumber = returnedData['total_items']
    }
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending

    this.loading = false
  }

  async fetchFromServer(page, rowsPerPage, filter, sortBy, descending) {
    let story_id = ''
    if (this.selectedStory) {
      story_id = this.selectedStory._id ?? ''
    }

    const items = await this.$store.dispatch(DELIVER_LOG.LOAD_STORY_ITEMS, {
      app_id: this.appId,
      story_id: story_id,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
      filter: {
        page: page,
        per_page: rowsPerPage,
        filter: filter,
        user_filter: this.queryParams,
        sort_by: sortBy,
        descending: descending,
      },
    })

    return items
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    if (!this.appId) {
      return
    }
    await this.loadResources()
    // await this.loadData()
    this.firstLoad = true
  }
}
